import HTTPStatus from 'http-status';
import { compact, get } from 'lodash';

import {
	DEFAULT_CREATE_SUCCESS,
	DEFAULT_DELETE_SUCCESS,
	DEFAULT_ERROR_MESSAGE,
	DEFAULT_ERROR_TITLE,
	DEFAULT_SUCCESS_TITLE,
	DEFAULT_UPDATE_SUCCESS,
	TOAST_TYPES,
	messageOverrides,
	titleOverrides,
} from '../../../constants/notification';

import { logout } from '../../auth/engine/auth.actions';

import { addToast } from './toasts.actions';

export const show = (dispatch, message, title, type = TOAST_TYPES.SUCCESS) => {
	dispatch(
		addToast({
			title,
			message,
			type,
		}),
	);
};

export const showSuccess = (
	dispatch,
	message,
	title = DEFAULT_SUCCESS_TITLE,
) => {
	show(dispatch, message, title, TOAST_TYPES.SUCCESS);
};

const parseErrors = (error) => {
	if (typeof error === 'string') return error;

	const errors =
		get(error, 'response.data.message') ||
		get(error, 'response.data.errors') ||
		[];

	if (typeof errors !== 'string' && errors.length > 0) {
		return compact(
			errors.reduce((errorsAcc, err) => {
				const messages = get(err, 'messages', []);

				if (messages.length > 0) {
					errorsAcc.push(
						compact(
							messages.reduce((messagesAcc, message) => {
								messagesAcc.push(
									messageOverrides[message.id] ||
										message.message,
								);

								return messagesAcc;
							}, []),
						).join('\n'),
					);
				} else if (err.detail) {
					errorsAcc.push(err.detail);
				}

				return errorsAcc;
			}, []),
		).join('\n');
	}

	if (typeof errors === 'string' && messageOverrides[errors]) {
		return messageOverrides[errors];
	}

	return DEFAULT_ERROR_MESSAGE;
};

const parseTitle = (error) => {
	const title = get(error, 'response.data.error');

	if (titleOverrides[title]) {
		return titleOverrides[title];
	}

	return title || DEFAULT_ERROR_TITLE;
};

export const showError = (dispatch, error, title) => {
	show(
		dispatch,
		parseErrors(error),
		title || parseTitle(error),
		TOAST_TYPES.FAIL,
	);
};

export const defaultErrorHandler = (dispatch, error) => {
	if (get(error, 'response.data.statusCode') === HTTPStatus.UNAUTHORIZED) {
		dispatch(logout());

		return showError(
			dispatch,
			'Your session has expired. Please log in again.',
		);
	}

	return showError(dispatch, error);
};

export const defaultCreateSuccess = (dispatch) => {
	showSuccess(dispatch, DEFAULT_CREATE_SUCCESS);
};

export const defaultDeleteSuccess = (dispatch) => {
	showSuccess(dispatch, DEFAULT_DELETE_SUCCESS);
};

export const defaultUpdateSuccess = (dispatch) => {
	showSuccess(dispatch, DEFAULT_UPDATE_SUCCESS);
};
