export const CONFIG = {
	timeout: 300000,
};

export const ENDPOINTS = {
	ABOUT_US: '/about-us',
	AUTH: '/auth',
	BROADCASTS: '/broadcasts',
	CATEGORIES: '/categories',
	INSTITUTIONS: '/institutions',
	HOMEPAGE_SETTINGS: '/homepage-settings',
	MEDIA_ITEMS: '/media-items',
	NEWSLETTER_SUBSCRIBERS: '/newsletter-subscribers',
	NEWSLETTER: '/newsletters',
	PAYMENTS: '/payments',
	PROMO_CODES: '/promo-codes',
	SUBMISSIONS: '/submissions',
	TAGS: '/tags',
	UPLOAD: '/upload',
	USERS: '/users',
};
